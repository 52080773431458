<template>
  <div class="mod-companyworkweeks">
    <el-form
      :inline="true"
      :model="searchForm"
      @keyup.enter.native="getDataList(1)"
    >
      <el-form-item>
        <el-input
          v-model="searchForm.name"
          placeholder="公司名"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="getDataList(1)">查询</el-button>
        <el-button
          v-if="isAuth('tc:companyworkweeks:save')"
          type="primary"
          @click="$dialog('addOrUpdate')"
          >新增</el-button
        >
        <el-button
          v-if="isAuth('tc:companyworkweeks:delete')"
          type="danger"
          @click="deleteHandle()"
          :disabled="dataListSelections.length <= 0"
          >批量删除</el-button
        >
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      border
      stripe
      ref="dataList"
      @select-all="handleSelectAll"
      @selection-change="selectionChangeHandle"
    >
      <el-table-column
        type="selection"
        header-align="center"
        align="center"
        width="50"
      >
      </el-table-column>
      <el-table-column
        prop="companyName"
        header-align="center"
        align="center"
        label="公司"
      >
      </el-table-column>
      <el-table-column
        prop="weeks"
        header-align="center"
        align="center"
        label="星期"
      >
      </el-table-column>
      <el-table-column
        prop="createdAt"
        header-align="center"
        align="center"
        label="创建时间"
      >
      </el-table-column>
      <el-table-column
        prop="updatedAt"
        header-align="center"
        align="center"
        label="更新时间"
      >
      </el-table-column>
      <el-table-column
        prop="createdBy"
        header-align="center"
        align="center"
        label="创建者"
      >
      </el-table-column>
      <el-table-column
        prop="updatedBy"
        header-align="center"
        align="center"
        label="修改者"
      >
      </el-table-column>
      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="150"
        label="操作"
      >
        <template slot-scope="scope">
          <el-button
            v-if="isAuth('tc:companyworkweeks:info')"
            type="text"
            size="small"
            @click="$dialog('addOrUpdate', scope.row.id, true)"
            >查看</el-button
          >
          <el-button
            v-if="isAuth('tc:companyworkweeks:update')"
            type="text"
            size="small"
            @click="$dialog('addOrUpdate', scope.row.id)"
            >修改</el-button
          >
          <el-button
            v-if="isAuth('tc:companyworkweeks:delete')"
            type="text"
            size="small"
            @click="deleteHandle(scope.row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update
      v-if="addOrUpdateVisible"
      ref="addOrUpdate"
      @refreshDataList="getDataList"
    ></add-or-update>
  </div>
</template>

<script>
import AddOrUpdate from './companyworkweeks-add-or-update';
import { listMixin, normal } from '@/mixins';

export default {
  mixins: [listMixin, normal],
  data() {
    return {
      searchForm: {
        name: '',
      },
      weeksList: [
        { id: 1, week: '一' },
        { id: 2, week: '二' },
        { id: 3, week: '三' },
        { id: 4, week: '四' },
        { id: 5, week: '五' },
        { id: 6, week: '六' },
        { id: 7, week: '日' },
      ],
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListSelections: [],
      companyList: [],
      addOrUpdateVisible: false,
    };
  },
  components: {
    AddOrUpdate,
  },
  activated() {
    this.getDataList(1);
  },
  created() {
    this.getCompanyList(1);
  },
  methods: {
    // 获取数据列表
    getDataList(page) {
      this.$http({
        url: '/tc/companyworkweeks/list',
        method: 'get',
        params: {
          page: page ? page : this.pageIndex,
          limit: this.pageSize,
          name: this.searchForm.name,
        },
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.dataList = data.page.records;
          this.totalPage = data.page.total;
        } else {
          this.dataList = [];
          this.totalPage = 0;
        }
      });
    },

    // 删除
    deleteHandle(id) {
      let ids = id
        ? [id]
        : this.dataListSelections.map((item) => {
            return item.id;
          });
      this.$confirm(`确定对选中数据进行[删除]操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$http({
            url: '/tc/companyworkweeks/delete',
            method: 'post',
            data: ids,
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              });
              this.getDataList();
            }
          });
        })
        .catch(() => {});
    },

    getCompanyList() {
      this.$http({
        url: `/tc/company/combo`,
        method: 'get',
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.companyList = data.list;
        }
      });
    },
  },
};
</script>
